import React from 'react';

import { Alert, Button, Table } from 'react-bootstrap';
import axios from 'axios';

import LeafletMapWithMarkers from './LeafletMapWithMarkers.js';
import "leaflet/dist/leaflet.css";

import NavBar from './NavBar.js';
import Footer from './Footer.js';

export default class StoneListView extends React.Component {
  // CLASS METHODS
    constructor( props, context ) {
      super( props, context );

      this.state = {};

      this.state['alertShow'] = false;
      this.state['alertMessage'] = '';
      this.state['alertNature'] = 'success';

      this.state.stoneList = [];

      this.state.sortBy = 'name';
      this.state.sortFlipped = false;
    }

    componentDidMount() {
      axios.get( this.props.config.backendURL + '/stones' )
        .then( res => {
          if ( res.status !== 200 ) {
            this.updateAlert( 'Failed to find list of stones', 'danger' );
          } else {
            var result = res.data.result;

            this.setState( { stoneList : result } );
          }
        } )
        .catch( error => {
          var alertMessage = 'Something went wrong';

          if ( error.response ) {
            alertMessage = error.response.data.message;
          }

          this.updateAlert( alertMessage, 'danger' );
        } );
    }

  // BASIC METHODS
    updateAlert( message, nature ) {
      this.setState( { alertMessage : message, alertNature : nature, alertShow : true } );
    }

    closeAlert = e => {
      this.setState( { alertShow : false } );
    }

  // TABLE
    getTable() {
      //console.log(this.state);
      return (
        <Table striped bordered hover size='sm'>
          <thead>
            <tr>
              <th onClick={this.handleColumnClick} className='archive-full-head'>
                Name
              </th>
              <th className='archive-full-head'>
                Class Name
              </th>
              <th className='archive-full-head'>
                Region
              </th>
              <th className='archive-full-head'>
                OS Grid Ref
              </th>
            </tr>
          </thead>
          <tbody>
            { this.state.stoneList.map( (symbol) => 
                  <tr key={symbol.id}   className='archive-full-body'>
                    <td><Button variant="link" href={'/stones/' +symbol.id}>{symbol.pictish_name}</Button></td>
                    <td><Button variant="link" href={'/classes/' +symbol.class_id}>{symbol.class_name}</Button></td>
                    <td><Button variant="link" href={'/stones/region/' +symbol.region_id}>{symbol.region_name}</Button></td>
                    <td>{symbol.mapref}</td>
                  </tr>
              )
            }
          </tbody>
        </Table>
      );
    }

    handleColumnClick = e => {
      switch ( e.currentTarget.innerText ) {
        case 'Name ' :
          if ( this.state.sortBy === 'name' ) {
            this.setState( { sortFlipped : !( this.state.sortFlipped ) } );
          } else {
            this.setState( { sortBy : 'name', sortFlipped : false } );
          }
          break;

        case 'Description ' :
          if ( this.state.sortBy === 'description' ) {
            this.setState( { sortFlipped : !( this.state.sortFlipped ) } );
          } else {
            this.setState( { sortBy : 'description', sortFlipped : false } );
          }
          break;

        case 'DD-MM-YYYY of scan(s) ' :
          if ( this.state.sortBy === 'datesofscans' ) {
            this.setState( { sortFlipped : !( this.state.sortFlipped ) } );
          } else {
            this.setState( { sortBy : 'datesofscans', sortFlipped : false } );
          }
          break;

        default :
          break;
      };
    }

  // RENDER
    render() {
      return (
        <React.Fragment>
          <NavBar pathname={this.props.location.pathname}/>
          <div className='mainbody'>
          { this.state.alertShow && 
              <Alert variant={this.state.alertNature} onClose={this.closeAlert} show={this.state.alertShow} dismissible>
                <p className='alertbody'>
                  {this.state.alertMessage}
                </p>
              </Alert>
            
          }
          <h2>List of All Stones</h2>
          Total: {this.state.stoneList.length }
          <LeafletMapWithMarkers props={this.state.stoneList} />  
          {this.getTable()}
          </div>
          <Footer />
        </React.Fragment>
      );
    }
}
