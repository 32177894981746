import React from 'react';

import './App.css';

import { BrowserRouter, Switch } from 'react-router-dom';

import AboutPageView from './components/AboutPageView';
import AppliedRoute from './AppliedRoute';
import APIPageView from './components/APIPageView';
import LandingPageView from './components/LandingPageView';
import ClassDetailView from './components/ClassDetailView';
import ClassListView from './components/ClassListView';
import StoneDetailView from './components/StoneDetailView';
import StoneListView from './components/StoneListView';
import StoneRegionView from './components/StoneRegionView';
import SymbolListView from './components/SymbolListView';
import SymbolDetailView from './components/SymbolDetailView';

import config from './config.js';

export default class App extends React.Component {

  // RENDER
    render() {
      const childProps = {
        config : config
      };

      return (
        <React.Fragment>
          <BrowserRouter>
            <Switch>
              <AppliedRoute exact path="/" component={LandingPageView} props={childProps}/>

              <AppliedRoute exact path="/about" component={AboutPageView} props={childProps}/>
              <AppliedRoute exact path="/symbols" component={SymbolListView} props={childProps}/>
              <AppliedRoute exact path="/symbols/:id" component={SymbolDetailView} props={childProps}/>
              <AppliedRoute exact path="/classes" component={ClassListView} props={childProps}/>
              <AppliedRoute exact path="/classes/:id" component={ClassDetailView} props={childProps}/>
              <AppliedRoute exact path="/stones" component={StoneListView} props={childProps}/>
              <AppliedRoute exact path="/stones/:id" component={StoneDetailView} props={childProps}/>
              <AppliedRoute exact path="/stones/region/:id" component={StoneRegionView} props={childProps}/>
              <AppliedRoute exact path="/stones/nearest/:latitude/:longitude" component={StoneDetailView} props={childProps}/>
              <AppliedRoute exact path="/api" component={APIPageView} props={childProps}/>
            </Switch>
          </BrowserRouter>
        </React.Fragment>
      );
    }
}
