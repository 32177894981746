import React from 'react';

import './LandingPageView.css';

import { Col, Row } from 'react-bootstrap';
import axios from 'axios';

import NavBar from './NavBar.js';
import Footer from './Footer.js';

import LeafletMapWithMarkers from './LeafletMapWithMarkers.js';
import "leaflet/dist/leaflet.css";

export default class LandingPageView extends React.Component {

    constructor( props, context ) {
      super( props, context );

      this.state = {};

      this.state['alertShow'] = false;
      this.state['alertMessage'] = '';
      this.state['alertNature'] = 'success';

      this.state.stoneList = [];
    }


    componentDidMount() {
      axios.get( this.props.config.backendURL + '/stones' )
        .then( res => {
          if ( res.status !== 200 ) {
            this.updateAlert( 'Failed to find list of stones', 'danger' );
          } else {
            var result = res.data.result;

            this.setState( { stoneList : result } );
          }
        } )
        .catch( error => {
          var alertMessage = 'Something went wrong';

          if ( error.response ) {
            alertMessage = error.response.data.message;
          }

          this.updateAlert( alertMessage, 'danger' );
        } );
    }


  // RENDER
    render() {
;      return (
        <React.Fragment>
          <NavBar pathname={this.props.location.pathname}/>
          
          <div className='mainbody'>
            <Row className="mt-2">
              <Col>
                <h3>Welcome to the Archaeoptics Pictish Symbol Stones Database</h3>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col s={6}>
              <p>
                This database was originally made available online in around 1996, one of the earlier database-driven websites in existence.
              </p>
              <p>
                Its original goals were:
                  <ul>
                  <li>To use various sources to determine historic and current locations of all Pictish stones</li>
                  <li>To visit those stones and acquire photographic images of the stones</li>
                  <li>To provide an easy to navigate database of the stones with mapping</li>
                  </ul>
              </p>
              <p>
              The database was taken offline in around 2004. Recently, it has been refreshed with new technology and made available mostly as a historic resource of data and imagery acquired 1995-2000.
              </p>
              </Col>
              <Col>
                <LeafletMapWithMarkers props={this.state.stoneList} />  
              </Col>
            </Row>
          </div>
          <Footer />
        </React.Fragment>
      );
    }
}