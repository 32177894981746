import React from 'react';

import { Alert, Button, Table } from 'react-bootstrap';
import axios from 'axios';

import LeafletMapWithMarkers from './LeafletMapWithMarkers.js';
import "leaflet/dist/leaflet.css";

import NavBar from './NavBar.js';
import Footer from './Footer.js';

export default class ClassDetailView extends React.Component {
  // CLASS METHODS
    constructor( props, context ) {
      super( props, context );

      this.state = {};

      this.state['alertShow'] = false;
      this.state['alertMessage'] = '';
      this.state['alertNature'] = 'success';

      this.state.classData = {};

      this.state.sortBy = 'name';
      this.state.sortFlipped = false;
    }

    componentDidMount() {
      axios.get( this.props.config.backendURL + '/classes/' + this.props.match.params.id )
        .then( res => {
          if ( res.status !== 200 ) {

            this.updateAlert( 'Failed to find list of classes', 'danger' );
          } else {
            var result = res.data.result;
            this.setState( { classData : result[0] } );
          }
        } )
        .catch( error => {
          var alertMessage = 'Something went wrong';

          if ( error.response ) {
            alertMessage = error.response.data.message;
          }

          this.updateAlert( alertMessage, 'danger' );
        } );
    }

  // BASIC METHODS
    updateAlert( message, nature ) {
      this.setState( { alertMessage : message, alertNature : nature, alertShow : true } );
    }

    closeAlert = e => {
      this.setState( { alertShow : false } );
    }

  // TABLE
    getTable() {
      //console.log(this.state);
      return (
        <Table striped bordered hover size='sm'>
          <thead>
            <tr>
              <th className='archive-full-head' style={{width : '150px'}}>
                Stone Name
              </th>
              <th className='archive-full-head'>
                Classification
              </th>
              <th className='archive-full-head'>
                Region
              </th>
              <th className='archive-full-head'>
                OS Grid Ref
              </th>
            </tr>
          </thead>
          <tbody>
            { this.state.classData.stones && this.state.classData.stones.map( (stone) => 
                  <tr key={stone.id} className='archive-full-body'>
                    <td><Button variant="link" href={'/stones/' +stone.id}>{stone.pictish_name}</Button></td>
                    <td><Button variant="link" href={'/classes/' +stone.class_id}>{stone.class_name}</Button></td>
                    <td><Button variant="link" href={'/stones/region/' +stone.region_id}>{stone.region_name}</Button></td>
                    <td>{stone.mapref}</td>
                  </tr>
              )
            }
          </tbody>
        </Table>
      );
    }
    //
    //
    handleRowClick = ( e, uuid ) => {
      window.location.href = '/stones/' + e.currentTarget.id;
    }

    handleColumnClick = e => {
      switch ( e.currentTarget.innerText ) {
        case 'Name ' :
          if ( this.state.sortBy === 'name' ) {
            this.setState( { sortFlipped : !( this.state.sortFlipped ) } );
          } else {
            this.setState( { sortBy : 'name', sortFlipped : false } );
          }
          break;

        case 'Description ' :
          if ( this.state.sortBy === 'description' ) {
            this.setState( { sortFlipped : !( this.state.sortFlipped ) } );
          } else {
            this.setState( { sortBy : 'description', sortFlipped : false } );
          }
          break;

        case 'DD-MM-YYYY of scan(s) ' :
          if ( this.state.sortBy === 'datesofscans' ) {
            this.setState( { sortFlipped : !( this.state.sortFlipped ) } );
          } else {
            this.setState( { sortBy : 'datesofscans', sortFlipped : false } );
          }
          break;

        default :
          break;
      };
    }

  // RENDER
    render() {
      return (
        <React.Fragment>
          <NavBar pathname={this.props.location.pathname}/>
          <div className='mainbody'>
          { this.state.alertShow && 
              <Alert variant={this.state.alertNature} onClose={this.closeAlert} show={this.state.alertShow} dismissible>
                <p className='alertbody'>
                  {this.state.alertMessage}
                </p>
              </Alert>
          }
          <h2>Stones with Classification {'stones' in this.state.classData ? this.state.classData.stones[0].class_name : ""}</h2>
          Total: {'stones' in this.state.classData ? this.state.classData.stones.length : ""}
          <LeafletMapWithMarkers props={this.state.classData.stones} />  
          {this.getTable()}
        </div>
        <Footer />
        </React.Fragment>
      );
    }
}
