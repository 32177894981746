import React from 'react';

import './AboutPageView.css';

import { Col, Row } from 'react-bootstrap';

import NavBar from './NavBar.js';
import Footer from './Footer.js';

export default class AboutPageView extends React.Component {
  // RENDER
    render() {
      return (
        <React.Fragment>
          <NavBar pathname={this.props.location.pathname}/>
          
          <div className='mainbody'>
            <Row>
            <Col xs={6}>
              <Row className="mt-2">
                <Col>
                  <h2>About Us</h2>
                </Col>
              </Row>
              <Row>
                <Col>
                Archaeoptics are a technology company specialising in 2D and 3D data archival, presentation and visualisation. Between 2000 and 2006, Archaeoptics pioneered the use of 3D laser scanning technology within the cultural heritage sector.
                <p/>
                Following the original mission statement of "recording the past for the future", Archaeoptics have come out of hibernation to apply their knowledge to historic data archival requirements.
                <p/>
                Please <a href="https://archaeoptics.co.uk">visit our main site</a> for other resources and information about our products and services.
                </Col>
              </Row>
              <Row className="mt-5">
                <Col>
                  <h2>Copyright Notice</h2>
                </Col>
              </Row>
              <Row >
                <Col>
                  All images and data remain the property of Archaeoptics, unless mentioned otherwise. Please do not copy or distribute any of the resources. If you are interested in access to the data, please contact us.
                </Col>
              </Row>
            </Col>
            <Col>
              <div className="mt-5" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
              <img alt="Archaeoptics" height="250" src="assets/archaeoptics_brand.png"/>
              </div>
            </Col>
            </Row>
          </div>
          <Footer />
        </React.Fragment>
      );
    }
}