import React from 'react';

import { Alert, Table } from 'react-bootstrap';
import axios from 'axios';

import NavBar from './NavBar.js';
import Footer from './Footer.js';

export default class SymbolListView extends React.Component {
  // CLASS METHODS
    constructor( props, context ) {
      super( props, context );

      this.state = {};

      this.state['alertShow'] = false;
      this.state['alertMessage'] = '';
      this.state['alertNature'] = 'success';

      this.state.symbolList = [];

      this.state.sortBy = 'name';
      this.state.sortFlipped = false;
    }

    componentDidMount() {
      axios.get( this.props.config.backendURL + '/symbols' )
        .then( res => {
          if ( res.status !== 200 ) {
            this.updateAlert( 'Failed to find list of symbols', 'danger' );
          } else {
            var result = res.data.result;

            this.setState( { symbolList : result } );
          }
        } )
        .catch( error => {
          var alertMessage = 'Something went wrong';

          if ( error.response ) {
            alertMessage = error.response.data.message;
          }

          this.updateAlert( alertMessage, 'danger' );
        } );
    }

  // BASIC METHODS
    updateAlert( message, nature ) {
      this.setState( { alertMessage : message, alertNature : nature, alertShow : true } );
    }

    closeAlert = e => {
      this.setState( { alertShow : false } );
    }

  // TABLE
    getTable() {
      //console.log(this.state);
      return (
        <Table striped bordered hover size='sm'>
          <thead>
            <tr>
              <th className='archive-full-head' style={{width : '150px'}}>
                Image
              </th>
              <th className='archive-full-head'>
                Symbol Name
              </th>
              <th className='archive-full-head'>
                Description
              </th>
            </tr>
          </thead>
          <tbody>
            { this.state.symbolList.map( (symbol) => 
                  <tr key={symbol.id} onClick={this.handleRowClick} id={symbol.id} className='archive-full-body'>
                    { symbol.symbol_url ?
                      <td>
                        <img onError={(e) => {e.target.src= ""}} alt={""} src={this.props.config.externalStaticURL+symbol.symbol_url} style={{ width : '100%', borderRadius : '7px'}}/>
                      </td>
                    :
                      <td>
                        Image missing
                      </td>
                    }
                    <td>{symbol.symbol_name}</td>
                    <td>{symbol.symbol_description}</td>
                  </tr>
              )
            }
          </tbody>
        </Table>
      );
    }
    //
    //
    handleRowClick = ( e, uuid ) => {
      window.location.href = '/symbols/' + e.currentTarget.id;
    }

    handleColumnClick = e => {
      switch ( e.currentTarget.innerText ) {
        case 'Name ' :
          if ( this.state.sortBy === 'name' ) {
            this.setState( { sortFlipped : !( this.state.sortFlipped ) } );
          } else {
            this.setState( { sortBy : 'name', sortFlipped : false } );
          }
          break;

        case 'Description ' :
          if ( this.state.sortBy === 'description' ) {
            this.setState( { sortFlipped : !( this.state.sortFlipped ) } );
          } else {
            this.setState( { sortBy : 'description', sortFlipped : false } );
          }
          break;

        case 'DD-MM-YYYY of scan(s) ' :
          if ( this.state.sortBy === 'datesofscans' ) {
            this.setState( { sortFlipped : !( this.state.sortFlipped ) } );
          } else {
            this.setState( { sortBy : 'datesofscans', sortFlipped : false } );
          }
          break;

        default :
          break;
      };
    }

  // RENDER
    render() {
      return (
        <React.Fragment>
          <NavBar pathname={this.props.location.pathname}/>
          <div className='mainbody'>
          { this.state.alertShow && 

              <Alert variant={this.state.alertNature} onClose={this.closeAlert} show={this.state.alertShow} dismissible>
                <p className='alertbody'>
                  {this.state.alertMessage}
                </p>
              </Alert>

          }
          <h2>Symbol List</h2>
          {this.getTable()}
          </div>
          <Footer />
        </React.Fragment>
      );
    }
}
