
import React from 'react';

import './Footer.css';

import { Col, Row } from 'react-bootstrap';

import { FaTwitter, FaFacebook } from 'react-icons/fa';

export default class Footer extends React.Component {

    render() {
    	return (
			<React.Fragment>
			<div className='footer'>
              <Row>
                <Col xs={6}>
                      	<p className="mainbody">
                      	<b>Archaeoptics</b>
                      	<br/>
<a href="https://archaeoptics.co.uk" target="_offsite">Archaeoptics</a> are a technology company specialising in 2D and 3D data archival, presentation and visualisation. 
						</p>
	            </Col>
	            <Col xs={4}>
		            <div className="socials">
		            	<a href="https://twitter.com/archaeoptics3d" target="_offsite"><FaTwitter size="30"/></a>
		            	<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
		            	<a href="https://facebook.com/archaeoptics" target="_offsite"><FaFacebook size="30"/></a>
		            </div>
		              <div className="copyright">
		                <b>Copyright(c)1995-2022 <a href="https://archaeoptics.co.uk" target="_offsite">Archaeoptics</a></b>
		              </div>
	            </Col>
              </Row>
			</div>
			</React.Fragment>
		);
    }
}
