
export function generateUUID() {
    let d = new Date().getTime();
    if(window.performance && typeof window.performance.now === "function"){
        d += performance.now(); //use high-precision timer if available
    }
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (d + Math.random()*16)%16 | 0;
        d = Math.floor(d/16);
        return (c==='x' ? r : ((r&0x3)|0x8)).toString(16);
    });
    return uuid.toUpperCase();
}

export function getAuthenticationState() {
    var uai = sessionStorage.getItem( 'userAccountInfo' );
    if ( uai ) {
        uai = JSON.parse( uai );
        return { isAuthenticated : true, userAccountInfo : uai }
    }

    return { isAuthenticated : false, userAccountInfo : {roles:[]}}
}

export function getAccountState() {
    var uAccount = sessionStorage.getItem( 'userAccount' );
    if ( uAccount ) {
        return uAccount;
    }

    var uai = sessionStorage.getItem( 'userAccountInfo' );
    if ( uai ) {
        uai = JSON.parse( uai );
        if (!uai.roles.includes('superadmin'))
            return uai.accountmnemonic;
    }

    return ''
}

export function handleRequestsError(error, errMessage) {

    let err = errMessage;
    let errCode = -1;
    if (error.response){
      err += '\nReason: ';
      err += error.response.data.message;
      errCode = error.response.status;
    }
    return { rescode: errCode, alertmessage : err, showalert: true, alertisfailure : true };
}
