import React from 'react';

import { Alert, Button, Table, Row, Col } from 'react-bootstrap';
import axios from 'axios';

import LeafletMapWithMarkers from './LeafletMapWithMarkers.js';
import "leaflet/dist/leaflet.css";

import NavBar from './NavBar.js';
import Footer from './Footer.js';

export default class SymbolDetailView extends React.Component {
  // CLASS METHODS
    constructor( props, context ) {
      super( props, context );

      this.state = {};

      this.state['alertShow'] = false;
      this.state['alertMessage'] = '';
      this.state['alertNature'] = 'success';

      this.state.symbolData = {'stones' : []};

      this.state.sortBy = 'name';
      this.state.sortFlipped = false;
    }

    componentDidMount() {
      axios.get( this.props.config.backendURL + '/symbols/' + this.props.match.params.id )
        .then( res => {
          if ( res.status !== 200 ) {
            this.updateAlert( 'Failed to find list of symbols', 'danger' );
          } else {
            var result = res.data.result;
            this.setState( { symbolData : result[0] } );
          }
        } )
        .catch( error => {
          var alertMessage = 'Something went wrong';

          if ( error.response ) {
            alertMessage = error.response.data.message;
          }

          this.updateAlert( alertMessage, 'danger' );
        } );
    }

  // BASIC METHODS
    updateAlert( message, nature ) {
      this.setState( { alertMessage : message, alertNature : nature, alertShow : true } );
    }

    closeAlert = e => {
      this.setState( { alertShow : false } );
    }

  // TABLE
    getTable() {
      //console.log(this.state);
      return (
          <Table striped bordered hover size='sm'>
            <thead>
              <tr>
                <th className='archive-full-head' style={{width : '150px'}}>
                  Stone Name
                </th>
                <th className='archive-full-head'>
                  Classification
                </th>
                <th className='archive-full-head'>
                  Region
                </th>
                <th className='archive-full-head'>
                  Map Reference
                </th>
              </tr>
            </thead>
            <tbody>
              { this.state.symbolData.stones && this.state.symbolData.stones.map( (stone) => 
                    <tr key={stone.id} onClick={this.handleRowClick} id={stone.id} className='archive-full-body'>
                      <td><Button variant="link" href={'/stones/' +stone.id}>{stone.pictish_name}</Button></td>
                      <td><Button variant="link" href={'/classes/' +stone.class_id}>{stone.class_name}</Button></td>
                      <td><Button variant="link" href={'/stones/region/' +stone.region_id}>{stone.region_name}</Button></td>
                      <td>{stone.mapref}</td>
                    </tr>
                )
              }
            </tbody>
          </Table>
      );
    }
    //
    //
    handleRowClick = ( e, uuid ) => {
      window.location.href = '/stones/' + e.currentTarget.id;
    }

    handleColumnClick = e => {
      switch ( e.currentTarget.innerText ) {
        case 'Name ' :
          if ( this.state.sortBy === 'name' ) {
            this.setState( { sortFlipped : !( this.state.sortFlipped ) } );
          } else {
            this.setState( { sortBy : 'name', sortFlipped : false } );
          }
          break;

        case 'Description ' :
          if ( this.state.sortBy === 'description' ) {
            this.setState( { sortFlipped : !( this.state.sortFlipped ) } );
          } else {
            this.setState( { sortBy : 'description', sortFlipped : false } );
          }
          break;

        case 'DD-MM-YYYY of scan(s) ' :
          if ( this.state.sortBy === 'datesofscans' ) {
            this.setState( { sortFlipped : !( this.state.sortFlipped ) } );
          } else {
            this.setState( { sortBy : 'datesofscans', sortFlipped : false } );
          }
          break;

        default :
          break;
      };
    }

  // RENDER
    render() {
      return (
        <React.Fragment>
          <NavBar pathname={this.props.location.pathname}/>
          <div className='mainbody'>
              { this.state.alertShow &&        
                  <Alert variant={this.state.alertNature} onClose={this.closeAlert} show={this.state.alertShow} dismissible>
                    <p className='alertbody'>
                      {this.state.alertMessage}
                    </p>
                  </Alert>
              }

              <h2>Stones with Symbol: {this.state.symbolData.symbol_name}</h2>
              <Row>
                <Col xs={4}>

                  <Row>
                    <Col>
                      <p className="mainbody">
                        {this.state.symbolData.symbol_description}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={3}>
                     <img onError={(e) => {e.target.src= ""}} alt={""} src={this.props.config.externalStaticURL+this.state.symbolData.symbol_url} style={{ width : '100%', borderRadius : '7px'}}/>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="mainbody">
                        Total: {this.state.symbolData.stones.length}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col xs={8}>
                    <LeafletMapWithMarkers props={this.state.symbolData.stones} />  
                </Col>
              </Row>

              {this.getTable()}
          </div>
          <Footer />
        </React.Fragment>
      );
    }
}
