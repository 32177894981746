
import L from 'leaflet'
import React from 'react';
// import { useEffect } from 'react'
// import { Button } from 'react-bootstrap';

// import { MapContainer, TileLayer, useMap } from 'react-leaflet';
// import VectorTileLayer from 'react-leaflet-vector-tile-layer';

import "./LeafletMapWithMarkers.css";

import proj4 from 'proj4';
import "proj4leaflet";

import { getAuthenticationState, getAccountState } from '../utils.js';

export default class LeafletMapWithMarkers extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = getAuthenticationState();
    this.state.account = getAccountState();
    this.state.map = "<div/>";
  }

  makeMarker( stonename, stoneid, lat, long ) {

    var blackIcon = new L.Icon({
      iconUrl: '/assets/leaflet-markers/marker-icon-2x-black.png',
      shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });

    var marker;
    if ( lat && long ) {
      /** Position */
      var position = []
      position.push(lat)
      position.push(long)
      

      var markertext = "<a href='/stones/"+stoneid+"' >"+stonename+"</a>";

      marker = L.marker( position, { title : stonename, icon : blackIcon } ).bindPopup( markertext );

    }

    return marker;
  }

  render() {

    const script = document.createElement("script");
    script.src = "https://labs.os.uk/public/os-api-branding/v0.3.0/os-api-branding.js";
    script.async = true;
    document.body.appendChild(script);

    return (
      <React.Fragment>
        <div id="map" style={{width: '100%',height: '500px'}}></div>
      </React.Fragment>
      ); 
  }



  componentWillReceiveProps(nextProps) {

      if (nextProps.props.length > 0 && 'latitude' in nextProps.props[0] && 'longitude' in nextProps.props[0]){
        this.state.map.setView([nextProps.props[0].latitude, nextProps.props[0].longitude], 13);
      }

      var markers = [];
      for (var i = 0; i < nextProps.props.length; i++){
        if ('latitude' in nextProps.props[i] && 'longitude' in nextProps.props[i]){
          var marker = this.makeMarker( nextProps.props[i].pictish_name, nextProps.props[i].id, nextProps.props[i].latitude, nextProps.props[i].longitude )
          if (marker){
            marker.addTo(this.state.map);
            markers.push(marker);

          }
        }
      }

      if ( markers.length !== 0 ) {      // what sets the zoom and centre for the markers to all fit on screen by default
            var group = new L.featureGroup(markers);
            this.state.map.fitBounds(group.getBounds());
          }

   }

  componentDidMount() {

    var apiKey = 'lWtTJp8dhDY1ACHgoUxVTxjeTGPRvoUP';
    var serviceUrl = 'https://api.os.uk/maps/raster/v1/zxy';

    // Setup the EPSG:27700 (British National Grid) projection.
    var crs = new L.Proj.CRS('EPSG:27700', '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 +units=m +no_defs', {
        resolutions: [ 896.0, 448.0, 224.0, 112.0, 56.0, 28.0, 14.0, 7.0, 3.5, 1.75 ],
        origin: [ -238375.0, 1376256.0 ]
    });

    // Transform coordinates.
    var transformCoords = function(arr) {
        return proj4('EPSG:27700', 'EPSG:4326', arr).reverse();
    };

    // Initialize the map.
    var mapOptions = {
        crs: crs,
        minZoom: 0,
        maxZoom: 9,
        center: transformCoords([ 337297, 503695 ]),
        zoom: 7,
        maxBounds: [
            transformCoords([ -238375.0, 0.0 ]),
            transformCoords([ 900000.0, 1376256.0 ])
        ],
        attributionControl: false
    };

    var map = this.state.map =  L.map('map', mapOptions);

    // Load and display ZXY tile layer on the map.
    var basemap = L.tileLayer(serviceUrl + '/Leisure_27700/{z}/{x}/{y}.png?key=' + apiKey).addTo(map);
  }

}