import React from 'react';

import './APIPageView.css';

import { Col, Row } from 'react-bootstrap';

import NavBar from './NavBar.js';
import Footer from './Footer.js';

export default class APIPageView extends React.Component {
  // RENDER
    render() {
      return (
        <React.Fragment>
          <NavBar pathname={this.props.location.pathname}/>
          
          <div className='mainbody'>
            <Row>
            <Col xs={6}>
              <Row className="mt-2">
                <Col>
                  <h2>Symbol Stone Database API</h2>
                </Col>
              </Row>
              <Row>
                <Col>
                The Symbol Stones Database has a REST API available to it which, when queried, will return data in JSON format.
                <p/>
                The base URL for the API is <b>https://api.symbolstones.archaeoptics.co.uk</b>. All of the listed endpoints below should be appended to this base URL.
                <p/>
                For example:
                <p/>
                <div className="blockquote2">curl -XGET https://api.symbolstones.archaeoptics.co.uk/stones/random</div>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col>
                  <h2>Fair Use</h2>
                </Col>
              </Row>
              <Row >
                <Col>
                  The API does not currently require an API key and there is minimal throttling in place. If the API is abused, it will be access controlled, throttled or removed entirely from service.
                </Col>
              </Row>
              <Row className="mt-5">
                <Col>
                  <h2>Copyright Notice</h2>
                </Col>
              </Row>
              <Row >
                <Col>
                  All images and data remain the property of Archaeoptics, unless mentioned otherwise. The data is made available for personal and research use but <b>cannot be used for any commercial purposes</b>.
                </Col>
              </Row>
              <Row className="mt-5">
                <Col>
                  <h2>REST Endpoints</h2>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col>
                  <h3>Symbols</h3>
                </Col>
              </Row>
              <Row >
                <Col>
                  <div className="blockquote2">/symbols</div>
                  <div className="blockquote2">/symbols/:id</div>
                  <p/>
                  Returns a JSON array of symbol information for all symbols unless qualified by a specific ID. Each data element will be of the following format:
                  <p/>
                  <div className="blockquote2">
                  <pre>
{JSON.stringify({"code":200,"message":"","result":[{"id":65,"stones":[{"class_id":5,"class_name":"Class I","id":70,"latitude":57.468122,"longitude":-3.358714,"lost":0,"mapref":"NJ 186 428","pictish_name":"Knockando 1","region_id":7,"region_name":"Moray and Banff"}],"symbol_description":"A circle with spokes radiating from the centre","symbol_name":"Spoked Wheel","symbol_url":"/symbols/65.jpg"}],"stacktrace":"","status":true,"type":"result"}, null, 2) }
</pre>
                  </div>
                  Symbol queries with a specific ID will contain a list of stones featuring that symbol. This is omitted from the "list all" endpoint.
                </Col>
              </Row>

              <Row className="mt-5">
                <Col>
                  <h3>Classes</h3>
                </Col>
              </Row>
              <Row >
                <Col>
                  <div className="blockquote2">/classes</div>
                  <div className="blockquote2">/classes/:id</div>
                  <p/>
                  Returns a JSON array of stone classification information for all classifications unless qualified by a specific ID. Each data element will be of the following format:
                  <p/>
                  <div className="blockquote2">
                  <pre>
{JSON.stringify({"code":200,"message":"","result":[{"class_name":"Hogback","description":"A type of recumbent grave slab typically long and low with a curved spine","id":10}],"stacktrace":"","status":true,"type":"result"}, null, 2) }
</pre>
                  </div>
                  Classification queries with a specific ID will contain a list of stones of that classification. This is omitted from the "list all" endpoint.
                </Col>
              </Row>

              <Row className="mt-5">
              <Col>
                <h3>Stones</h3>
              </Col>
              </Row>
              <Row >
                <Col>
                  <div className="blockquote2">/stones</div>
                  <div className="blockquote2">/stones/:id</div>
                  <p/>
                  Returns a JSON array of stone information for all stones unless qualified by a specific ID. Each data element will be of the following format:
                  <p/>
                  <div className="blockquote2">
                  <pre>
{JSON.stringify({"code":200,"message":"","result":[{"access":"A","animals":0,"canmore_url":12674,"class_id":5,"class_name":"Class I","damaged":1,"description":"This stone is currently on display in Inverness Museum & Art Gallery.\n\nIt features two symbols incised in a very simple, but effective, style. The uppermost symbol is the rare triple-oval symbol. Beneath that is a very plain crescent and V-rod.\n\n<I>Visited 26th January, 2000</I>","dimensions":"0.71m high x 0.45m wide x 0.11m thick","figures":0,"id":56,"latitude":57.47719099999999,"longitude":-4.224999,"lost":0,"mapref":"NH 6668 4518","pictish_name":"Wester Balblair","rating":4,"region_id":8,"region_name":"Inverness, Nairn, Badenoch and Strathspey","sublocation":"","town":"Inverness"}],"stacktrace":"","status":true,"type":"result"}, null, 2) }
</pre>
                  </div>
                  Stone queries with a specific ID will contain a list of locations and media for that stone. This is omitted from the "list all" endpoint.
                  <p/>
                </Col>
              </Row>
              <Row >
                <Col>
                  <div className="blockquote2">/stones/random</div>
                  <p/>
                  Will return a JSON array of stones randomly from the list.
                  <p/>
                </Col>
              </Row>
              <Row >
                <Col>
                  <div className="blockquote2">/stones/nearest/:latitude/:longitude</div>
                  <div className="blockquote2">/stones/nearest/:latitude/:longitude/:clamp</div>
                  <p/>
                  Returns a JSON array of stone information for all stones ordered by increasing distance from the supplied coordinate.
                  In the first API call, all records will be returned. In the second form, only the number of records specified by the clamp value will be returned,
                  i.e., a clamp value of 1 will return the closest record to the stone.
                  <p/>
                  Records without valid locations will return a distance of 999999.
                  <p/>
                </Col>
              </Row>

            </Col>
            <Col>
              <div className="mt-5" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
              <img alt="Aberlemno Pictish Stone" src="assets/04042002_kirkyard_front_bwink.jpg"/>
              </div>
            </Col>
            </Row>
          </div>
          <Footer />
        </React.Fragment>
      );
    }
}