import React from 'react';

import { Alert, Button, OverlayTrigger, Tooltip, Row, Col, Table } from 'react-bootstrap';
import axios from 'axios';

import NavBar from './NavBar.js';
import Footer from './Footer.js';

import LeafletMapWithMarkers from './LeafletMapWithMarkers.js';
import "leaflet/dist/leaflet.css";
//import "https://labs.os.uk/public/os-api-branding/v0.3.0/os-api-branding.css"

export default class StoneDetailView extends React.Component {
  // CLASS METHODS
    constructor( props, context ) {
      super( props, context );

      this.state = {};

      this.state['alertShow'] = false;
      this.state['alertMessage'] = '';
      this.state['alertNature'] = 'success';

      this.state.stoneData = {};

      this.state.sortBy = 'name';
      this.state.sortFlipped = false;
    }

    getStoneInfo( nearestMode, url ) {
        axios.get( url )
          .then( res => {
            if ( res.status !== 200 ) {
              this.updateAlert( 'Failed to find list of stones', 'danger' );
            } else {
              var result = res.data.result;

              if ( nearestMode ) {
                /** Strip out the stone object and throw away the distance */
                this.setState( { stoneData : result[0]['stone'] } );
              } else {
                this.setState( { stoneData : result[0] } );
              }
            }
          } )
          .catch( error => {
            var alertMessage = 'Something went wrong';

            if ( error.response ) {
              alertMessage = error.response.data.message;
            }

            this.updateAlert( alertMessage, 'danger' );
          } );
    }

    componentDidMount() {

      /** Figure out if this is running in 'nearest' mode */
      var url = '';
      var nearestMode = ('latitude' in this.props.match.params) && ('longitude' in this.props.match.params);
      if ( nearestMode ) {
        url = this.props.config.backendURL + '/stones/nearest/' + this.props.match.params.latitude + '/' + this.props.match.params.longitude + '/1';
        axios.get( url )
          .then( res => {
            if ( res.status !== 200 ) {
              this.updateAlert( 'Failed to find nearest stones', 'danger' );
            } else {
              var result = res.data.result;

              /**
               * Make another call to fetch the detail record for the nearest stone.
               * We only have a stub record here
               */
              if ( result.length === 1 && 'stone' in result[0] && 'id' in result[0]['stone'] ) {
                url = this.props.config.backendURL + '/stones/' + result[0]['stone']['id'];
                this.getStoneInfo( false, url );
              }
            }
          }
        )
      } else {
        url = this.props.config.backendURL + '/stones/' + this.props.match.params.id;

        this.getStoneInfo( false, url );
      }
    }

  // BASIC METHODS
    updateAlert( message, nature ) {
      this.setState( { alertMessage : message, alertNature : nature, alertShow : true } );
    }

    closeAlert = e => {
      this.setState( { alertShow : false } );
    }

  // TABLE
    getTable() {
      // console.log(this.state);
      return (
        <React.Fragment>

        <h2>{this.state.stoneData.pictish_name}</h2>


        <Row>
          <Col xs={4}>
              <Row>
              <Col xs={3}>Classification</Col>
              <Col><Button variant="link" href={'/classes/' +this.state.stoneData.class_id} >{this.state.stoneData.class_name}</Button>
              </Col>
              </Row>
              <Row>
              <Col xs={3}>Dimensions</Col>
              <Col>{this.state.stoneData.dimensions}</Col>
              </Row>
              <Row>
              <Col xs={3}>OS Grid Ref</Col>
              <Col>{this.state.stoneData.mapref}</Col>
              </Row>
              <Row>
              <Col xs={3}>Region</Col>
              <Col><Button variant="link" href={'/stones/region/' +this.state.stoneData.region_id} >{this.state.stoneData.region_name}</Button></Col>
              </Row>
              <Row>
              <Col xs={3}>Town</Col>
              <Col>{this.state.stoneData.town}</Col>
              </Row>
              <Row>
              <Col xs={3}>Location</Col>
              <Col>{this.state.stoneData.sublocation}</Col>
              </Row>
              <Row>
              <Col xs={3}>Symbols</Col>
              <Col xs={6}>
              { this.state.stoneData.symbols && this.state.stoneData.symbols.map( (sym) => 
                
                  <OverlayTrigger
                      placement="right"
                      delay={{ show: 250, hide: 400 }}
                      overlay={<Tooltip id="button-tooltip">{sym.symbol_name}</Tooltip>}>
                  <Button variant="link" href={'/symbols/' +sym.symbol_id}><img onError={(e) => {e.target.src= ""}} alt={""} src={this.props.config.externalStaticURL + sym.symbol_url} responsive width="50"/></Button>
                  </OverlayTrigger>
                
                )
              }
              </Col>
              </Row>

            </Col>
            <Col xs={8}>
              <LeafletMapWithMarkers props={[this.state.stoneData]} />  
            </Col>
          </Row>

        </React.Fragment>
      );
    }

    getDescription(){
      return (
        <React.Fragment>
          <Row> 
            <Col>
              <h2>Description</h2>
              <p className='mainbody'>
              {this.state.stoneData.description}       
              </p>
            </Col>
          </Row>
        </React.Fragment>
      );

    }

    getPhotos(){

      return(
        <React.Fragment>
        <h2>Photos</h2>
        <Row>
        { this.state.stoneData.media && this.state.stoneData.media.map( (media) => 
          <Col>
            <Row>
            <Col>
            <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="button-tooltip">{media.media_description}</Tooltip>}>
            <img alt={media.media_description} onError={(e) => {e.target.src= ""}} src={this.props.config.externalStaticURL + "/media/"+media.media_url} responsive />
            </OverlayTrigger>
            </Col>
            </Row>
            <Row>
            <Col>
            {media.media_date}
            </Col>
            </Row>
          </Col>
          )
        }
        </Row>
        </React.Fragment>
      )
    }

    getPreviousLocationTable() {
      return (
        <React.Fragment>
        <h2>Locational Information </h2>
        <Row>
        <Col>
        <Table striped bordered hover size='sm'>
          <thead>
            <tr>
              <th style={{width : '250px'}}>
                Location Date
              </th>
              <th>Description</th>
              <th>Town</th>
              <th>OS Map Ref</th>
            </tr>
          </thead>
          <tbody>
            { this.state.stoneData.locations && this.state.stoneData.locations.map( (loc) => 
                  <tr key={loc.id} id={loc.id} className='archive-full-body'>
                    
                    <td>{loc.location_date}</td>
                    <td>{loc.sublocation}</td>
                    <td>{loc.town}</td>
                    <td>{loc.mapref}</td>
                  </tr>
              )
            }
          </tbody>
        </Table>
        </Col>
        </Row>
        </React.Fragment>
      );
    }

    getLinks(){
      return(
        <React.Fragment>
        <h2>Links</h2>
        <Row>
          <Col>
          <Table striped bordered hover size='sm'>
            <thead>
              <tr>
                <th style={{width : '250px'}}>
                  Source
                </th>
                <th>Link</th>
              </tr>
            </thead>
            <tbody>
              <tr>
              <td>
                <img alt="Megalithic Portal Link" src="/assets/megalithic_logo_75.png" responsive />
              </td>
              <td>
                <a target="_offsite" href={'https://www.megalithic.co.uk/article.php?lat='+this.state.stoneData.latitude+'&lon='+this.state.stoneData.longitude}>Megalithic Portal</a>
              </td>
              </tr>
            </tbody>            
          </Table>
          </Col>
        </Row>
        </React.Fragment>
      )
    }

    //
    //
    handleRowClick = ( e, uuid ) => {
      window.location.href = '/' + e.currentTarget.id;
    }
    handleClassRowClick = ( e, uuid ) => {
      window.location.href = '/' + e.currentTarget.id;
    }

    handleColumnClick = e => {
      switch ( e.currentTarget.innerText ) {
        case 'Name ' :
          if ( this.state.sortBy === 'name' ) {
            this.setState( { sortFlipped : !( this.state.sortFlipped ) } );
          } else {
            this.setState( { sortBy : 'name', sortFlipped : false } );
          }
          break;

        case 'Description ' :
          if ( this.state.sortBy === 'description' ) {
            this.setState( { sortFlipped : !( this.state.sortFlipped ) } );
          } else {
            this.setState( { sortBy : 'description', sortFlipped : false } );
          }
          break;

        case 'DD-MM-YYYY of scan(s) ' :
          if ( this.state.sortBy === 'datesofscans' ) {
            this.setState( { sortFlipped : !( this.state.sortFlipped ) } );
          } else {
            this.setState( { sortBy : 'datesofscans', sortFlipped : false } );
          }
          break;

        default :
          break;
      };
    }

  // RENDER
    render() {
      return (
        <React.Fragment>
          <NavBar pathname={this.props.location.pathname}/>
          { this.state.alertShow && 
            <div className='mainbody'>
              <Alert variant={this.state.alertNature} onClose={this.closeAlert} show={this.state.alertShow} dismissible>
                <p className='alertbody'>
                  {this.state.alertMessage}
                </p>
              </Alert>
            </div>
          }
        <div className='mainbody'>
          {this.getTable()}
          {'description' in this.state.stoneData && this.state.stoneData.description !== 0 ? this.getDescription() : <React.Fragment/>}
          {this.getPreviousLocationTable()}
          {this.getPhotos()}
          {this.getLinks()}
        </div>
        <Footer />
        </React.Fragment>
      );
    }
}
